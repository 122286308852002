<template>
  <div class="page inPage pullRefreshPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">PullRefresh</div>
    </div>
    <div class="pageBody">
      <lee-pull-refresh pullingText='下拉刷新' @refresh="onRefresh">
        <ul class="pics">
          <li v-for="(item,xh) in pic" :key="xh" v-show="item.tpic"><img :src="item.tpic"></li>
        </ul>
      </lee-pull-refresh>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {ref, onMounted} from 'vue'
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
const pic = ref([])
onMounted(() => {
    fetch('https://www.fastmock.site/mock/5f854ec44ed74b246903c6e75806ec14/api/article').then((json) => {
        return json.json()
    }).then((rs) => {
        pic.value = rs
    })
})
const onRefresh = () => {
    shuffle(pic.value)
}
const shuffle = (arr) => {
    let i = arr.length;
    while (i) {
        let j = Math.floor(Math.random() * i--);
        [arr[j], arr[i]] = [arr[i], arr[j]];
    }
    return arr
}
</script>
<style>
.pics {
  display: flex;
  flex-wrap: wrap;
}

.pics li {
  width: 33.33%;
  padding: 16% 0;
  height: 0;
  position: relative;
}

.pics li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border:solid 1px #fff;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}

.pullRefreshPage .pageBody {
  min-height: calc(100% - 156px);
}
</style>
